import play from "../assets/play.svg";
import pause from "../assets/pause.svg";
import volumeUp from "../assets/volume-up.svg";
import volumeDown from "../assets/volume-down.svg";
import volumeOff from "../assets/volume-off.svg";
import full from "../assets/full.svg";
import fullWeb from "../assets/full-web.svg";
import right from "../assets/right.svg";
import comment from "../assets/comment.svg";
import commentOff from "../assets/comment-off.svg";
import send from "../assets/send.svg";
import pallette from "../assets/pallette.svg";
import camera from "../assets/camera.svg";
import loading from "../assets/loading.svg";

const Icons = {
  play: play,
  pause: pause,
  volumeUp: volumeUp,
  volumeDown: volumeDown,
  volumeOff: volumeOff,
  full: full,
  fullWeb: fullWeb,
  right: right,
  comment: comment,
  commentOff: commentOff,
  send: send,
  pallette: pallette,
  camera: camera,
  loading: loading,
};

export default Icons;
